<template>
  <div class="giftdpt">
    <div v-title
         data-title="首存送礼"></div>

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/gift_banner3.jpg`" />
    </div>

    <div class="deposit-box">
      <div class="deposit-img"
           v-if="tabindex == 1 || tabindex == 0">
        <img :src="`${imgUrl}pcbb/oneheavy.png`" />
      </div>
      <div class="deposit-img"
           v-if="tabindex == 2">
        <img :src="`${imgUrl}pcbb/oneheavy2.png`" />
      </div>
      <div class="deposit-img"
           v-if="tabindex == 3">
        <img :src="`${imgUrl}pcbb/oneheavy3.png`" />
      </div>
      <div class="deposit-acondition">
        贝宝体育加倍彩⾦狂欢夜！<br />新会员注册福利享受不停，三重福利等你来拿，优惠天天有
      </div>

      <div class="deposit-tab">
        <!-- tab栏 -->
        <div class="deposit-tab-content">
          <div class="deposit-item deposit-item1"
               :class="tabindex == 1 || tabindex == 0 ? 'deposit-item-active2' : ''
            "
               @click="changetab(1)">
            第一重
            <p v-if="tabindex == 1 || tabindex == 0">首存送彩金</p>
          </div>
          <div class="deposit-item deposit-item1"
               :class="tabindex == 2 ? 'deposit-item-active2' : ''"
               @click="changetab(2)">
            第二重
            <p v-if="tabindex == 2">首存送彩金</p>
          </div>
          <div class="deposit-item deposit-item1"
               :class="tabindex == 3 ? 'deposit-item-active2' : ''"
               @click="changetab(3)">
            第三重
            <p v-if="tabindex == 3">每日最高送5%</p>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-schedule"
               v-if="tabindex == 1 || tabindex == 0">
            首存进度:<span v-if="list.zt == 1">{{ list.dpted }}</span><span v-else-if="list.zt != 1 && list.zt != 0">已过期</span><span v-else-if="list.zt == 0">未激活</span>
            <p>(当天存款24小时内可累计，只可领取一次)</p>
          </div>
          <div class="rule-content-schedule"
               v-if="tabindex == 2">
            复存进度:<span v-if="list.zt == 2">{{ list.dpted2 }}</span><span v-else-if="list.zt != 2 && list.zt != 0">已过期</span><span v-else-if="list.zt == 0">未激活</span>
            <p>(当天存款24小时内可累计，只可领取一次)</p>
          </div>

        </div>

        <!-- 第一重 -->
        <!-- <table v-if="tabindex == 1 || tabindex == 0">
          <thead>
            <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
              <td
                style="
                  color: #fff;
                  border-top-left-radius: 10px;
                  border-right: 1px solid #e2d2bf;
                "
              >
                首存金额
              </td>
              <td style="color: #fff; border-right: 1px solid #e2d2bf">
                对应彩金
              </td>
              <td style="color: #fff; border-top-right-radius: 10px">
                领取彩金
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item1, index1) in list.onelist" :key="index1">
              <td>{{ item1.dpt_money }}</td>
              <td>{{ item1.grade_money }}</td>
              <td>
                <div
                  class="getout"
                  v-if="list.dpted < item1.dpt_money && list.zt == 1"
                >
                  暂无资格
                </div>
                <div class="geting" v-else-if="item1.qu_zt == 1">
                  已领取
                </div>
                <div class="getout" v-else-if="list.zt == 0">
                  未激活
                </div>
                <div class="getout" v-else-if="list.zt != 1">
                  已过期
                </div>
                <div
                  class="geting"
                  v-else-if="item1.qu_zt == 0 && list.zt == 1"
                  @click="DptGiftMoney(item1.id)"
                >
                  领取
                </div>
                <div class="getout" v-else>无法领取</div>
              </td>
            </tr>
          </tbody>
        </table> -->

        <!-- 第二重 -->
        <table v-if="tabindex == 2">
          <thead>
            <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
              <td style="
                            color: #fff;
                            border-top-left-radius: 10px;
                            border-right: 1px solid #e2d2bf;
                          ">
                首存金额
              </td>
              <td style="color: #fff; border-right: 1px solid #e2d2bf">
                对应彩金
              </td>
              <td style="color: #fff; border-top-right-radius: 10px">
                领取彩金
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item2, index2) in list.twolist"
                :key="index2">
              <td>{{ item2.dpt_money }}</td>
              <td>{{ item2.grade_money }}</td>
              <td>
                <div class="getout"
                     v-if="list.dpted2 < item2.dpt_money && list.zt == 2">
                  暂无资格
                </div>
                <div class="geting"
                     v-else-if="item2.qu_zt == 1">
                  已领取
                </div>
                <div class="getout"
                     v-else-if="list.zt == 0">
                  未激活
                </div>
                <div class="getout"
                     v-else-if="list.zt != 2">
                  已过期
                </div>

                <div class="geting"
                     v-else-if="item2.qu_zt == 0 && list.zt == 2"
                     @click="DptGiftMoney(item2.id)">
                  领取
                </div>
                <div class="getout"
                     v-else>无法领取</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="tabindex == 2"
             class="giftormoney-smalltitle"
             style="margin-top:20px;color: #7D8AB4;">
          （可当天24⼩时内累计）
        </div>

        <!-- 第三重 -->
        <table v-if="tabindex == 3">
          <thead style="
                          border-top-left-radius: 10px;
                          border-top-right-radius: 10px;
                        ">
            <tr style="
                        background: linear-gradient(90deg, #4581fa, #63b3fc)
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                      ">
              <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                              border-top-left-radius: 10px;
                            ">
                VIP等级
              </th>
              <th style="
                              color: #fff;
                              border-right: 1px solid #e2d2bf;
                              font-family: PingFang SC;
                              font-weight: 400;
                            ">
                彩金比例
              </th>

              <th style="color: #fff; border-top-right-radius: 10px">
                流水倍数
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list.list"
                :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.dpt_rate }}%</td>
              <td v-if="index == 0"
                  :rowspan="list.list.length">1倍</td>
            </tr>
          </tbody>
        </table>
        <div v-if="tabindex == 3"
             class="giftormoney-smalltitle"
             style="margin-top:20px;color: #7D8AB4;">
          新⽼会员每⽇⾸存款返利2%～5%<br />彩⾦+本⾦1倍流⽔出款
        </div>

        <!-- 礼物兑换 -->
        <div class="giftormoney-box"
             v-if="tabindex == 1 || tabindex == 0">
          <!-- <div class="giftormoney-title"></div> -->
          <div style="padding-top: 5px;">
            <div class="giftormoney-smalltitle">
              满足首存金额额度,领取对应奖品
            </div>
          </div>

          <div class="giftormoney-tab"
               id="giftormoney-tab">
            <div class="giftormoney-tab-item"
                 :class="tabgiftindex == index ? 'giftormoney-tabactive' : ''"
                 @click="changegiftinde(index)"
                 v-for="(item, index) in list.moneyList"
                 :key="index">
              {{ item.money }}
            </div>
          </div>

          <div class="gift-box"
               v-if="list.moneyList">
            <!-- 实物 -->
            <div class="gift-box-left"
                 v-if="list.moneyList[tabgiftindex].money != 500">
              <div class="gift-box-title">实物领取</div>
              <div class="gift-box-content">
                <div class="gift-box-content-item"
                     v-if="list.giftList">
                  <img :src="list.giftList[tabgiftindex].pic[0].url" />
                  <p>{{ list.moneyList[tabgiftindex].gift }}</p>
                  <div class="btns2"
                       v-if="list.moneyList[tabgiftindex].money > list.dpted &&
                    list.zt == 1
                    ">
                    暂无资格
                  </div>
                  <div class="btns2"
                       v-else-if="list.zt == 0">
                    未激活
                  </div>
                  <div class="btns2"
                       v-else-if="list.zt != 1">
                    已过期
                  </div>
                  <div class="btns"
                       v-else-if="list.moneyList[tabgiftindex].is_gift2money == 1 &&
                    list.limit_cs == 0 &&
                    list.zt == 1
                    "
                       @click="gopopupcontent(list.moneyList[tabgiftindex].id)">
                    实物领取
                  </div>
                  <div class="btns2"
                       v-else-if="list.limit_cs == 1">
                    已领取
                  </div>
                  <div class="btns2"
                       v-else>
                    不可领取
                  </div>
                </div>
              </div>
            </div>
            <div class="gift-box-middle"
                 v-if="list.moneyList[tabgiftindex].money != 500">
              OR
            </div>
            <!-- 折现 -->
            <div class="gift-box-right"
                 :class="list.moneyList[tabgiftindex].money == 500
              ? 'gift-box-rightact'
              : ''
              ">
              <div class="gift-box-title">折现领取</div>
              <div class="gift-box-content">
                <div class="gift-box-content-item"
                     v-if="list.moneyList">
                  <img :src="`${imgUrl}pcbb/handsel.png`" />
                  <p>折现金额{{ list.moneyList[tabgiftindex].player_money }}</p>
                  <div class="btns2"
                       v-if="list.moneyList[tabgiftindex].money > list.dpted &&
                    list.zt == 1
                    ">
                    暂无资格
                  </div>
                  <div class="btns2"
                       v-else-if="list.zt == 0">
                    未激活
                  </div>
                  <div class="btns2"
                       v-else-if="list.zt != 1">
                    已过期
                  </div>
                  <div class="btns"
                       v-else-if="list.moneyList[tabgiftindex].is_gift2money == 1 &&
                    list.limit_cs == 0 &&
                    list.zt == 1
                    "
                       @click="discounting(list.moneyList[tabgiftindex].id)">
                    折现领取
                  </div>
                  <div class="btns2"
                       v-else-if="list.limit_cs == 1">
                    已领取
                  </div>
                  <div class="btns2"
                       v-else>
                    不可领取
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="giftormoney-smalltitle"
               style="margin-top:20px;color: #7D8AB4;">
            注:以上礼品只能领取一个
          </div>
        </div>
      </div>
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"
               style="margin-top: 0"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                统计方式:首次存款后24小时内累计的存款额度全部列入领取标准。
              </li>
              <li>
                <span>2.</span>
                领取规则:每个会员只能领取一次奖励，奖励为固定彩金+折现彩金或实物，需要在首次存款24小时内领取，过期作废。
              </li>
              <li>
                <span>3.</span>
                第二重活动将首存24小时后开始生效，第二重活动开始期间24小时内存款可累计有效，不领取过期作废。
              </li>
              <li>
                <span>4.</span>
                折现彩金:领取时点击“折现领取”，工作人员审核完毕后系统会自动发放彩金到会员账户上。
              </li>
              <li>
                <span>5.</span>
                实物领取:领取时点击“实物领取”，根据提示填入您的个人收货地址等信息，在15个工作日内将实物通过快递的方式寄送到所填的地址。
              </li>
              <li>
                <span>6.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>7.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格；
              </li>
              <li>
                <span>8.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
    <van-popup v-model="showpopup"
               position="bottom"
               :style="{ height: '80%' }"
               v-if="list.giftList">
      <div class="giftbox2">
        <div v-for="(i1, v1) in list.giftList[popupindex].pic"
             :key="v1">
          <div><img :src="i1.url" /></div>
          <div>{{ i1.name }}</div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="showpopup2"
               position="bottom"
               :style="{ height: '70%' }">
      <div class="giftbox2">
        <van-field v-model="content.name"
                   label="收件人"
                   placeholder="请输入收件人姓名" />
        <van-field v-model="content.phone"
                   type="tel"
                   label="手机号"
                   placeholder="请输入收件人手机号" />
        <van-field v-model="content.addressP"
                   label="省"
                   placeholder="请输入省" />
        <van-field v-model="content.addressC"
                   label="市"
                   placeholder="请输入市" />
        <van-field v-model="content.address"
                   label="详细地址"
                   placeholder="请输入详细地址" />

        <div class="btn-box">
          <div @click="material">立即申请</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
import axios from 'axios'
export default {
  data() {
    return {
      tabindex: 1,
      is_token: false,
      list: [],
      giftindex: 0,
      tabgiftindex: 0,
      pitchon: '',
      showpopup: false,
      popupindex: 0,
      active: 1,
      radio: null,
      showpopup2: false,
      content: {
        name: '',
        phone: '',
        addressP: '',
        addressC: '',
        address: ''
      },
      ischeck: false,
      dpted: 0,
      isshow: false,
      marginLefts: '20px',
      cueTheme: ''

    }
  },
  mounted() {
    this.cueTheme = window.localStorage.getItem('cueTheme')

    this.getdata()
  },
  methods: {
    changeisshow() {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    gopopupcontent(id) {
      if (window.localStorage.getItem('opentype') == 'h5') {
        Toast('该活动仅在app开发,请下载APP')
        return
      }
      this.changegiftid = id
      this.showpopup2 = true
    },
    onChange(index) {
      if (+this.list.dpted <= this.list.moneyList[index].money) {
        Toast('您未满足领取条件')
        return
      }
      this.radio = index
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },
    changetab(index) {
      if (index === this.tabindex) return
      this.tabindex = index
    },
    changegiftinde(index) {
      if (index === this.tabgiftindex) return
      if (index >= 3) {
        document.getElementById('giftormoney-tab').scrollLeft = index * 50
        console.log(document.getElementById('giftormoney-tab').scrollLeft)
      } else {
        document.getElementById('giftormoney-tab').scrollLeft = 0
      }

      this.tabgiftindex = index
    },
    getdata() {
      var _this = this
      var data = '?type=DptGift'
      getdata(data).then((res) => {
        res.message.list.reverse()

        _this.list = res.message
        _this.tabindex = res.message.zt
      })
    },

    changegift(item, index) {
      this.giftindex = index
      //选中的礼物
      this.pitchon = item
    },
    showPopup(index) {
      this.showpopup = true
      this.popupindex = index
    },
    DptGiftMoney(id) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'DptGiftMoney',
                  gift_type: id
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'DptGiftMoney',
          //       gift_type: id
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'DptGiftMoney',
              giftId: id
            }
          })
        }
      })
    },
    discounting(id) {
      // if (window.localStorage.getItem('opentype') == 'h5') {
      //   Toast('该活动仅在app开发,请下载APP')
      //   return
      // }
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'DptGiftDiscounting',
                giftId: id,
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              urlApply: 'DptGiftDiscounting',
              giftId: id
            }
          })
        }
      })
    },
    material() {
      if (this.content.name == '') {
        Toast('请输入收件人姓名')
        return
      }
      if (this.content.phone == '') {
        Toast('请输入收件人手机')
        return
      }
      if (this.content.addressP == '') {
        Toast('请输入省')
        return
      }
      if (this.content.addressC == '') {
        Toast('请输入市')
        return
      }
      if (this.content.address == '') {
        Toast('请输入详细地址')
        return
      }

      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'DptGiftMaterial',
                giftId: this.changegiftid,
                content: this.content,
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              urlApply: 'DptGiftMaterial',
              giftId: this.changegiftid,
              content: this.content
            }
          })
          this.showpopup2 = false
        }
      })
    },
  }
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

/deep/ .van-nav-bar .van-icon {
  color: #000;
}

* {
  margin: 0;
  padding: 0;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f5f5f5;
}

.imgbox {
  > img {
    width: 100%;
    display: flex;
  }
}

.rightbox {
  position: absolute;
  top: 150px;
  right: 0;
  background-color: #b2987f;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  // padding: 5px 5px 5px 0;
  color: #fff;

  .content {
    display: flex;
    align-items: center;

    > div {
      &:nth-child(1) {
        width: 38px;
        height: 38px;
        border: 4px solid #fff;
        border-radius: 50%;
        margin-right: 5px;

        img {
          width: 30px;
          height: 30px;
        }
      }

      .primary_color {
        font-size: 14px;
        line-height: 1.2em;
        margin-right: 10px;
      }
    }
  }
}

.deposit-box {
  margin: 10px;
  padding: 10px;
  background-color: var(--w-bgcolor);
  border-radius: 10px;
  padding-top: 15px;
  box-shadow: 0px 1px 6px 0px #d7dae2;

  .deposit-img {
    img {
      width: 164px;
      transform: translate(50%, 0%);
    }
  }

  .deposit-acondition {
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #7d8ab4;
    line-height: 20px;
  }

  .deposit-tab {
    .deposit-tab-content {
      display: flex;
      height: 35px;
      line-height: 35px;
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 3px 5px 0px rgba(25, 52, 150, 0.25);
      border-radius: 14px;

      .deposit-item {
        flex: 1;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #96b0c7;
      }

      .deposit-item1 {
        font-size: 15px;
        line-height: 35px;
        position: relative;

        p {
          font-size: 12px;
          position: absolute;
          bottom: -3px;
          right: 50%;
          transform: translateX(50%) scale(0.85);
          white-space: nowrap;
          // transform: ;
        }
      }

      .deposit-item-active {
        color: #fff;
        background: url('https://begood.gz.bcebos.com/pc/img/discount/conditionact1.png') no-repeat;
        background-size: 100% 105%;
        image-rendering: -moz-crisp-edges !important;
        image-rendering: -o-crisp-edges !important;
        image-rendering: -webkit-optimize-contrast !important;
        image-rendering: crisp-edges !important;
        -ms-interpolation-mode: nearest-neighbor !important;
        margin-top: -5px;
        line-height: 38px;
      }

      .deposit-item-active2 {
        color: #fff;
        background: url('https://begood.gz.bcebos.com/pc/img/discount/conditionact1.png') no-repeat;
        background-size: 100% 105%;
        image-rendering: -moz-crisp-edges !important;
        image-rendering: -o-crisp-edges !important;
        image-rendering: -webkit-optimize-contrast !important;
        image-rendering: crisp-edges !important;
        -ms-interpolation-mode: nearest-neighbor !important;
        margin-top: -5px;
        line-height: 26px;
      }
    }
  }

  .rule-bottom-img {
    height: 34px;
    background: url('../assets/img/pcbb/rulebottomimg.png');
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges !important;
    image-rendering: -o-crisp-edges !important;
    image-rendering: -webkit-optimize-contrast !important;
    image-rendering: crisp-edges !important;
    -ms-interpolation-mode: nearest-neighbor !important;
  }
}

.rule-condition {
  padding: 0 16px;

  .rule-condition-title {
    height: 34px;
    background: url('https://begood.gz.bcebos.com/h5/webviewImg/pcbb/conditiontitl.png');
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges !important;
    image-rendering: -o-crisp-edges !important;
    image-rendering: -webkit-optimize-contrast !important;
    image-rendering: crisp-edges !important;
    -ms-interpolation-mode: nearest-neighbor !important;
    position: relative;
  }

  .rule-acontents {
    padding-left: 10px;
    margin-top: 15px;

    .title-item {
      margin-bottom: 10px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #418beb;

      > span {
        color: #303030;
      }
    }
  }
}

.rule-content {
  padding: 0 16px;

  .rule-content-schedule {
    margin-top: 20px;
    text-align: center;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: var(--li-colortext);

    > span {
      color: #3b86fe;
    }

    > p {
      font-size: 13px !important;
      color: var(--li-colortext);
      text-align: center;
    }
  }
}

.rule-detail {
  padding: 0 16px;

  .rule-detail-title {
    margin-top: 20px;
    height: 34px;
    background: url('https://begood.gz.bcebos.com/h5/webviewImg/pcbb/detailtitle.png');
    background-size: 100% 100%;
    image-rendering: -moz-crisp-edges !important;
    image-rendering: -o-crisp-edges !important;
    image-rendering: -webkit-optimize-contrast !important;
    image-rendering: crisp-edges !important;
    -ms-interpolation-mode: nearest-neighbor !important;
    position: relative;
  }

  .activity_rules {
    margin-top: 20px;

    ul li {
      color: var(--li-colortext);
      margin-bottom: 10px;
      position: relative;
      padding-left: 18px;
      font-size: 13px;
      line-height: 1.5em;

      > span {
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 0;
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.activityRules {
  margin: 0 15px;
  border-radius: 8px;
  background-color: #fff;

  .title {
    padding: 20px 0;
    width: 100%;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    color: #c8a57e;
    position: relative;
  }
}

.getout {
  width: 70%;
  margin: 0 auto;
  background: #9ec3ff;
  border-radius: 17px;
  padding: 4px 0;
  font-size: 12px;
  color: #fff;
}

.geting {
  width: 70%;
  margin: 0 auto;
  background: #3b86fe;
  border-radius: 17px;
  padding: 4px 0;
  font-size: 12px;
  color: #fff;
}

.giftormoney-box {
  margin-top: 20px;

  .giftormoney-title {
    height: 34px;
    background: url('https://begood.gz.bcebos.com/h5/webviewImg/pcbb/giftormoneytitle.png');
    image-rendering: -moz-crisp-edges !important;
    image-rendering: -o-crisp-edges !important;
    image-rendering: -webkit-optimize-contrast !important;
    image-rendering: crisp-edges !important;
    -ms-interpolation-mode: nearest-neighbor !important;
    background-size: 100% 100%;
    position: relative;
  }

  .giftormoney-tab {
    padding: 0 3px;
    width: 100%;
    overflow-x: scroll;
    // height: 20px;
    line-height: 22px;
    background: #ffffff;
    box-shadow: 0px 3px 5px 0px rgba(25, 52, 150, 0.25);
    border-radius: 14px;
    display: flex;
    align-items: center;

    .giftormoney-tab-item {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      font-size: 13px;
    }

    .giftormoney-tabactive {
      background: url('https://begood.gz.bcebos.com/pc/img/discount/conditionact1.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }

  .gift-box {
    display: flex;
    align-items: center;
    justify-content: center;

    .gift-box-left {
      width: 48%;

      .gift-box-title {
        font-size: 16px;
        margin: 20px 0;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3b86fe;
        text-align: center;
      }

      .gift-box-content {
        display: flex;
        max-height: 214px;

        .gift-box-content-item {
          padding: 10px;
          flex: 1;
          background: #ffffff;
          border-radius: 5px;
          box-shadow: 0px 1px 1.6vw 0px #d7dae2;

          &:nth-child(1) {
            margin-right: 2%;
          }

          &:nth-child(2) {
            margin-left: 2%;
          }

          img {
            width: 100%;
          }

          p {
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            font-size: 10px;
            text-align: center;
            margin: 10px 0;
            height: 25px;
            transform: scale(0.9);
          }

          .btns {
            background: #3b86fe;
            border-radius: 11px;
            font-size: 10px;
            color: #fff;
            margin: 0 auto;
            padding: 4px 0;
            text-align: center;
            transform: scale(0.9);
          }

          .btns2 {
            background: #999;
            border-radius: 11px;
            font-size: 10px;
            color: #fff;
            margin: 0 auto;
            padding: 4px 0;
            text-align: center;
            transform: scale(0.9);
          }
        }
      }
    }

    .gift-box-middle {
      margin: 0 2%;
      margin-top: 60px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3b86fe;
    }

    .gift-box-right {
      width: 48%;

      .gift-box-title {
        font-size: 16px;
        margin: 20px 0;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3b86fe;
        text-align: center;
      }

      .gift-box-content {
        display: flex;
        max-height: 214px;

        .gift-box-content-item {
          padding: 10px;
          flex: 1;
          background: #ffffff;
          border-radius: 5px;
          box-shadow: 0px 1px 1.6vw 0px #d7dae2;

          img {
            width: 100%;
            max-height: 214px;
            padding: 15px;
          }

          p {
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            font-size: 10px;
            text-align: center;
            margin: 10px 0;
            height: 25px;
            transform: scale(0.9);
          }

          .btns {
            background: #3b86fe;
            border-radius: 11px;
            font-size: 10px;
            color: #fff;
            margin: 0 auto;
            padding: 4px 0;
            text-align: center;
            transform: scale(0.9);
          }

          .btns2 {
            background: #999;
            border-radius: 11px;
            font-size: 10px;
            color: #fff;
            margin: 0 auto;
            padding: 4px 0;
            text-align: center;
            transform: scale(0.9);
          }
        }
      }
    }
  }
}

.giftormoney-smalltitle {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #3b86fe;
  text-align: center;
  margin: 10px 0;
}

.gift-active-img {
  position: relative;
  height: 120px;
  margin: 0 auto;
  margin-bottom: 20px;

  .gift-active-img-bottom {
    position: absolute;
    bottom: 0;
  }

  .gift-active-img-left {
    height: 80px;
    left: 12%;
    position: absolute;
    bottom: 10px;
  }

  .gift-active-img-right {
    height: 80px;
    right: 20px;
    position: absolute;
    bottom: 10px;
  }
}

.btn-box {
  margin: 20px;

  > div {
    margin: 8px 0;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgb(69, 129, 250), rgb(99, 179, 252));
    color: #fff;
    text-align: center;
    border-radius: 5px;

    &:nth-child(3) {
      background-color: #fff;
      color: #b2987f;
      border: 1px solid #b2987f;
    }
  }
}

.gift-box-rightact {
  width: 45% !important;
}
</style>
